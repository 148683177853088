import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import SignupView from '../views/SignupView.vue';
import SigninView from '../views/SigninView.vue';
import AuthView from '../views/AuthView.vue';
import VerifyEmailView from '../views/VerifyEmailView.vue';
import PhraseView from '../views/PhraseView.vue';
import ForgotPasswordView from '../views/ForgotPasswordView.vue';
import ForgotPasswordView2 from '../views/ForgotPasswordView2.vue';
import ResetPasswordView from '../views/ResetPasswordView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "sign-up",
    component: SignupView,
    meta: {
      title: "Sign up",
    },
  },
  {
    path: "/sign-up",
    name: "sign-up2",
    component: SignupView,
    meta: {
      title: "Sign up",
    },
  },

  {
    path: "/sign-in",
    name: "sign-in",
    component: SigninView,
    meta: {
      title: "Sign in",
    },
  },

  {
    path: "/auth",
    name: "auth",
    component: AuthView,
    meta: {
      title: "Auth",
    },
  },

  {
    path: "/verify-email",
    name: "verify-email",
    component: VerifyEmailView,
    meta: {
      title: "Verify email",
    },
  },

  {
    path: "/phrase",
    name: "phrase",
    component: PhraseView,
    meta: {
      title: "Phrase",
    },
  },

  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPasswordView,
    meta: {
      title: "Forgot password",
    },
  },

  {
    path: "/forgot-password2",
    name: "forgot-password2",
    component: ForgotPasswordView2,
    meta: {
      title: "Forgot password 2",
    },
  },

  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPasswordView,
    meta: {
      title: "Reset password",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      top: 0,
    };
  },
});

export default router
