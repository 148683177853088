import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDAbAOOsTKgioTnd_H6DqiUQVMczXBnbg4",
  authDomain: "bitpokket.firebaseapp.com",
  projectId: "bitpokket",
  storageBucket: "bitpokket.appspot.com",
  messagingSenderId: "1039439742438",
  appId: "1:1039439742438:web:04099dff55cd88d92e4227",
  measurementId: "G-5006886X9F",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
