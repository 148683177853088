<template>
  <VerifyEmailBody/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VerifyEmailBody from '@/components/VerifyEmailBody.vue'; 

export default defineComponent({
  name: 'VerifyEmailView',
  components: {
    VerifyEmailBody,
  },
});
</script>
