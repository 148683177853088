<template>
  <ForgotPasswordBody/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ForgotPasswordBody from '@/components/ForgotPasswordBody.vue'; 

export default defineComponent({
  name: 'ForgotPasswordView',
  components: {
    ForgotPasswordBody,
  },
});
</script>
