<template>
    
    <div class="fixed top-0 left-0 z-50 w-full bg-n-8/90 backdrop-blur-sm border-b border-n-6 lg:bg-n-8/90 lg:backdrop-blur-sm">

                <div class="flex items-center h-[4.75rem] px-5 lg:h-[5.25rem] lg:px-7.5 xl:px-10">
                    
                    <a class="block w-[11.875rem] xl:mr-8" href="https://www.navox.io">
                        <img alt="Brainwave" fetchpriority="high" width="190" height="40" decoding="async" data-nimg="1" src="@/assets/img/logo_long.png" style="color: transparent"/>
                    </a>
                    <nav class="hidden fixed top-[4.8125rem] left-0 right-0 bottom-0 bg-n-8 lg:static lg:flex lg:mx-auto lg:bg-transparent">
                        <div class="relative z-2 flex flex-col items-center justify-center m-auto lg:flex-row">
                            
                            <a class="block relative font-code text-2xl uppercase text-n-1 transition-colors hover:text-color-1 px-6 py-6 md:py-8 lg:-mr-0.25 lg:text-xs lg:font-semibold lg:text-n-1/50 lg:leading-5 lg:hover:text-n-1 xl:px-12" href="https://www.navox.io" style="font-family: 'Neue Machina';">
                                Home
                <div class="hidden absolute left-0 bottom-0 w-0.25 h-1.5 bg-n-6 lg:block"></div>
                                <div class="hidden absolute right-0 bottom-0 w-0.25 h-1.5 bg-n-6 lg:block"></div>
                            </a>

                            <a class="block relative font-code text-2xl uppercase text-n-1 transition-colors hover:text-color-1 px-6 py-6 md:py-8 lg:-mr-0.25 lg:text-xs lg:font-semibold lg:text-n-1/50 lg:leading-5 lg:hover:text-n-1 xl:px-12" href="https://www.navox.io/about" style="font-family: 'Neue Machina';">
                                About
                <div class="hidden absolute left-0 bottom-0 w-0.25 h-1.5 bg-n-6 lg:block"></div>
                                <div class="hidden absolute right-0 bottom-0 w-0.25 h-1.5 bg-n-6 lg:block"></div>
                            </a>

                            <a class="block relative font-code text-2xl uppercase text-n-1 transition-colors hover:text-color-1 px-6 py-6 md:py-8 lg:-mr-0.25 lg:text-xs lg:font-semibold lg:text-n-1/50 lg:leading-5 lg:hover:text-n-1 xl:px-12" href="https://www.navox.io/staking" style="font-family: 'Neue Machina';">
                                Staking
                <div class="hidden absolute left-0 bottom-0 w-0.25 h-1.5 bg-n-6 lg:block"></div>
                                <div class="hidden absolute right-0 bottom-0 w-0.25 h-1.5 bg-n-6 lg:block"></div>
                            </a>

                            <a class="block relative font-code text-2xl uppercase text-n-1 transition-colors hover:text-color-1 px-6 py-6 md:py-8 lg:-mr-0.25 lg:text-xs lg:font-semibold lg:text-n-1/50 lg:leading-5 lg:hover:text-n-1 xl:px-12" href="https://www.navox.io/contact" style="font-family: 'Neue Machina';">
                                Contact
                <div class="hidden absolute left-0 bottom-0 w-0.25 h-1.5 bg-n-6 lg:block"></div>
                                <div class="hidden absolute right-0 bottom-0 w-0.25 h-1.5 bg-n-6 lg:block"></div>
                            </a>

                            <!-- <a class="block relative font-code text-2xl uppercase text-n-1 transition-colors hover:text-color-1 lg:hidden px-6 py-6 md:py-8 lg:-mr-0.25 lg:text-xs lg:font-semibold lg:text-n-1/50 lg:leading-5 lg:hover:text-n-1 xl:px-12" href="/login?new=true" style="font-family: 'Neue Machina';">
                                languagee
                <div class="hidden absolute left-0 bottom-0 w-0.25 h-1.5 bg-n-6 lg:block"></div>
                                <div class="hidden absolute right-0 bottom-0 w-0.25 h-1.5 bg-n-6 lg:block"></div>
                            </a> -->

                            <!-- <a class="block relative font-code text-2xl uppercase text-n-1 transition-colors hover:text-color-1 lg:hidden px-6 py-6 md:py-8 lg:-mr-0.25 lg:text-xs lg:font-semibold lg:text-n-1/50 lg:leading-5 lg:hover:text-n-1 xl:px-12" href="/login">
                                Sign inn
                <div class="hidden absolute left-0 bottom-0 w-0.25 h-1.5 bg-n-6 lg:block"></div>
                                <div class="hidden absolute right-0 bottom-0 w-0.25 h-1.5 bg-n-6 lg:block"></div>
                            </a> -->
                        </div>
                        <div class="absolute inset-0 pointer-events-none lg:hidden">
                            <div class="absolute inset-0 opacity-[.03]">
                                <img alt="Background" loading="lazy" width="688" height="953" decoding="async" data-nimg="1" class="inline-block align-top opacity-0 transition-opacity false w-full h-full object-cover" src="@/assets/img/background.webp" style="color: transparent"/>
                            </div>
                            <div class="absolute top-1/2 left-1/2 w-[51.375rem] aspect-square border border-n-2/10 rounded-full -translate-x-1/2 -translate-y-1/2">
                                <div class="absolute top-1/2 left-1/2 w-[36.125rem] aspect-square border border-n-2/10 rounded-full -translate-x-1/2 -translate-y-1/2"></div>
                                <div class="absolute top-1/2 left-1/2 w-[23.125rem] aspect-square border border-n-2/10 rounded-full -translate-x-1/2 -translate-y-1/2"></div>
                            </div>
                            <div class="absolute top-0 left-5 w-0.25 h-full bg-n-6"></div>
                            <div class="absolute top-0 right-5 w-0.25 h-full bg-n-6"></div>
                            <div class="absolute top-[4.4rem] left-16 w-3 h-3 bg-gradient-to-b from-[#DD734F] to-[#1A1A32] rounded-full"></div>
                            <div class="absolute top-[12.6rem] right-16 w-3 h-3 bg-gradient-to-b from-[#B9AEDF] to-[#1A1A32] rounded-full"></div>
                            <div class="absolute top-[26.8rem] left-12 w-6 h-6 bg-gradient-to-b from-[#88E5BE] to-[#1A1A32] rounded-full"></div>
                        </div>
                    </nav>

                    <!-- LANGUAGE DESKTOP -->

                    <div id="Anutrickz_translateBTN" class=" mr-8 text-n-1/50 transition-colors hover:text-n-1" style="font-family: 'Neue Machina';">

                            <img src="@/assets/img/translate.png" alt="" style="width: 40px; margin-top: 5px; cursor: pointer;">

                            <div class="hide" id="ATLang_list">
                                <ul>
                                    <li class='language-list' data-google-lang='en'>English</li>
                                    <li class='language-list' data-google-lang='fr'>French</li>
                                    <li class='language-list' data-google-lang='de'>German</li>
                                    <li class='language-list' data-google-lang='es'>Spanish</li>
                                    <li class='language-list' data-google-lang='nl'>Dutch</li>
                                    <li class='language-list' data-google-lang='fi'>Finnish</li>
                                    <li class='language-list' data-google-lang='it'>Italian</li>
                                    <li class='language-list' data-google-lang='ar'>Arabic</li>
                                    <li class='language-list' data-google-lang='zh-TW'>Chinese</li>
                                    <li class='language-list' data-google-lang='ja'>Japanese</li>
                                    <li class='language-list' data-google-lang='tr'>Turkish</li>
                                </ul>
                            </div>
                                        
                        </div>


                        <a class="mm-none button relative inline-flex items-center justify-center h-11 px-7 text-n-8 transition-colors hover:text-color-1" href="https://account.navox.io/sign-in" >
                            <span class="relative z-10" style="font-family: 'Montserrat Alternates'; font-weight: 900;">Sign in</span>
                            <svg class="absolute top-0 left-0" width="21" height="44" viewBox="0 0 21 44">
                                <path fill="#ffffff" stroke="white" stroke-width="2" d="M21,43.00005 L8.11111,43.00005 C4.18375,43.00005 1,39.58105 1,35.36365 L1,8.63637 C1,4.41892 4.18375,1 8.11111,1 L21,1"></path>
                            </svg>
                            <svg class="absolute top-0 left-[1.3125rem] w-[calc(100%-2.625rem)]" height="44" viewBox="0 0 100 44" preserveAspectRatio="none" fill="#ffffff">
                                <polygon fill="#ffffff"   fill-rule="nonzero" points="100 0 100 44 0 44 0 0"></polygon>
                            </svg>
                            <svg class="absolute top-0 right-0" width="21" height="44" viewBox="0 0 21 44">
                                <path fill="#ffffff" stroke="white" stroke-width="2" d="M0,43.00005 L5.028,43.00005 L12.24,43.00005 C16.526,43.00005 20,39.58105 20,35.36365 L20,16.85855 C20,14.59295 18.978,12.44425 17.209,10.99335 L7.187,2.77111 C5.792,1.62675 4.034,1 2.217,1 L0,1"></path>
                            </svg>
                        </a>    
                    
                    <!-- BURGER OPEN -->
                    <button id="navOpen" class="button relative inline-flex items-center justify-center h-11 px-3 text-n-1 transition-colors hover:text-color-1 ml-auto lg:hidden">
                        <span class="relative z-10">
                            <svg class="overflow-visible" width="20" height="12" viewBox="0 0 20 12">
                                <rect class="transition-all origin-center" y="0" width="20" height="2" rx="1" fill="white" transform="rotate(0)"></rect>
                                <rect class="transition-all origin-center" y="10" width="20" height="2" rx="1" fill="white" transform="rotate(0)"></rect>
                            </svg>
                        </span>
                        <svg class="absolute top-0 left-0" width="21" height="44" viewBox="0 0 21 44">
                            <path fill="none" stroke="#00ff00" stroke-width="2" d="M21,43.00005 L8.11111,43.00005 C4.18375,43.00005 1,39.58105 1,35.36365 L1,8.63637 C1,4.41892 4.18375,1 8.11111,1 L21,1"></path>
                        </svg>
                        <svg class="absolute top-0 left-[1.3125rem] w-[calc(100%-2.625rem)]" height="44" viewBox="0 0 100 44" preserveAspectRatio="none" fill="#00ff00">
                            <polygon fill="url(#btn-top)" fill-rule="nonzero" points="100 42 100 44 0 44 0 42"></polygon>
                            <polygon fill="url(#btn-bottom)" fill-rule="nonzero" points="100 0 100 2 0 2 0 0"></polygon>
                        </svg>
                        <svg class="absolute top-0 right-0" width="21" height="44" viewBox="0 0 21 44">
                            <path fill="none" stroke="#00ff00" stroke-width="2" d="M0,43.00005 L5.028,43.00005 L12.24,43.00005 C16.526,43.00005 20,39.58105 20,35.36365 L20,16.85855 C20,14.59295 18.978,12.44425 17.209,10.99335 L7.187,2.77111 C5.792,1.62675 4.034,1 2.217,1 L0,1"></path>
                        </svg>
                    </button>
                </div>
    </div>

</template>

<style scoped>

    @import url(../assets/css/gTranslate.css);

</style>

<script>
    export default {
        
        mounted(){

            window.addEventListener("click", function (e) {
            if (document.getElementById("Anutrickz_translateBTN").contains(e.target)) {
                //inside box
                return;
            } 
            
            //outside box
            document.getElementById("ATLang_list").classList.remove("show");
            document.getElementById("ATLang_list").classList.add("hide");

            });

            let navOpen = document.getElementById("navOpen");
            let navClose = document.getElementById("navClose");
            let mobileBox = document.getElementById("mobileBox");

            navOpen.addEventListener("click", function (e) {
                mobileBox.classList.remove("hidden");
            });

            navClose.addEventListener("click", function (e) {
                mobileBox.classList.add("hidden");
            });

        }

    }
</script>