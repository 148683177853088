<template>
  <ForgotPasswordBody2/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ForgotPasswordBody2 from '@/components/ForgotPasswordBody2.vue'; 

export default defineComponent({
  name: 'ForgotPasswordView2',
  components: {
    ForgotPasswordBody2,
  },
});
</script>
