<template>
  <SignupBody/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SignupBody from '@/components/SignupBody.vue'; 

export default defineComponent({
  name: 'SignupView',
  components: {
    SignupBody,
  },
});
</script>
