<template>
  <SigninBody/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SigninBody from '@/components/SigninBody.vue'; 

export default defineComponent({
  name: 'SigninView',
  components: {
    SigninBody,
  },
});
</script>
