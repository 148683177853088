<template>
  
  <div class="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">

           <!--NAVBAR  -->
           <NavBody/>

            
           <!-- SIGN UP -->
           <div class="relative py-10 lg:py-16 xl:py-20  flex min-h-[calc(100vh-4.8125rem)] overflow-hidden lg:min-h-[calc(100vh-5.3125rem)]">
                <div class="container relative z-2 max-w-[68rem] m-auto lg:flex lg:justify-between">
                    <div class="max-w-[32.875rem] mx-auto mb-12 text-center md:mb-16 lg:flex lg:flex-col lg:justify-around lg:max-w-[23.75rem] lg:m-0 lg:text-left">

                        <div class="hidden tagline flex items-center lg:flex" style="margin-bottom: -80px;"><svg width="5" height="14" viewBox="0 0 5 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 0.822266H1V12.8223H5" stroke="url(#brackets-left)"></path><defs><linearGradient id="brackets-left" x1="50%" x2="50%" y1="0%" y2="100%"><stop offset="0%" stop-color="#89F9E8"></stop><stop offset="100%" stop-color="#FACB7B"></stop></linearGradient></defs></svg><div class="mx-3 text-n-3">Sign up</div><svg width="5" height="14" viewBox="0 0 5 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M-2.98023e-08 0.822266H4V12.8223H-2.98023e-08" stroke="url(#brackets-right)"></path><defs><linearGradient id="brackets-right" x1="14.635%" x2="14.635%" y1="0%" y2="100%"><stop offset="0%" stop-color="#9099FC"></stop><stop offset="100%" stop-color="#D87CEE"></stop></linearGradient></defs></svg></div>

                        <h2 class="h2" >Your Journey to Crypto Wealth Begins Here </h2>
                        <p class="hidde body-2 mt-4 text-n-4 md:block">By signing up, you're gaining access to a powerful crypto wallet that empowers you to explore the vast crypto landscape. </p>
                        <!-- <p class="hidde body-2 mt-4 text-n-4 md:block">By signing up, you're gaining access to a powerful crypto wallet that empowers you to explore the vast crypto landscape. Whether you're a seasoned investor or just starting, our platform provides the tools and resources you need to succeed in the crypto market.</p> -->
                    </div>
                    <form class="relative max-w-[23.5rem] mx-auto p-0.25 bg-conic-gradient rounded-3xl lg:flex-1 lg:max-w-[27.5rem] lg:m-0 xl:mr-12" @submit.prevent="signUp">
                        <div class="px-9 py-10 bg-n-8 rounded-[1.4375rem] lg:px-16 lg:py-[3.25rem]">
                            <div class="relative mb-4 lg:mb-5">
                                <img alt="Mail" loading="lazy" width="24" height="24" decoding="async" data-nimg="1" class="inline-block align-top opacity-0 transition-opacity opacity-100 absolute top-4 left-0 w-6 pointer-events-none" src="@/assets/img/user.png" style="color: transparent;">
                                <input class="w-full h-14 pl-12 bg-transparent border-b border-n-1/15 font-light placeholder:text-n-4 outline-none transition-colors focus:border-n-1/30" placeholder="Full name" type="text" v-model="name" required>
                            </div>

                            
                            
                            <div class="relative mb-4 lg:mb-5">
                                <img alt="Mail" loading="lazy" width="24" height="24" decoding="async" data-nimg="1" class="inline-block align-top opacity-0 transition-opacity opacity-100 absolute top-4 left-0 w-6 pointer-events-none" src="@/assets/img/mail-01.svg" style="color: transparent;">
                                <input class="w-full h-14 pl-12 bg-transparent border-b border-n-1/15 font-light placeholder:text-n-4 outline-none transition-colors focus:border-n-1/30" placeholder="Email" type="email" v-model="email" required>
                            </div>
                            <div class="relative mb-4 lg:mb-5">
                                <img alt="Lock" loading="lazy" width="24" height="24" decoding="async" data-nimg="1" class="inline-block align-top opacity-0 transition-opacity opacity-100 absolute top-4 left-0 w-6 pointer-events-none" src="@/assets/img/lock-03.svg" style="color: transparent;">
                                <input class="w-full h-14 pl-12 bg-transparent border-b border-n-1/15 font-light placeholder:text-n-4 outline-none transition-colors focus:border-n-1/30" placeholder="Password" type="password" v-model="password" required>
                            </div>

                            <div class="relative mb-4 lg:mb-5">
                                <img alt="Lock" loading="lazy" width="24" height="24" decoding="async" data-nimg="1" class="inline-block align-top opacity-0 transition-opacity opacity-100 absolute top-4 left-0 w-6 pointer-events-none" src="@/assets/img/lock-03.svg" style="color: transparent;">
                                <input class="w-full h-14 pl-12 bg-transparent border-b border-n-1/15 font-light placeholder:text-n-4 outline-none transition-colors focus:border-n-1/30" placeholder="Confirm password" type="password" v-model="cPassword" required>
                            </div>

                            <div class="relative mb-4 lg:mb-5">
                                <img alt="Lock" loading="lazy" width="24" height="24" decoding="async" data-nimg="1" class="inline-block align-top opacity-0 transition-opacity opacity-100 absolute top-4 left-0 w-6 pointer-events-none" src="@/assets/img/lock-03.svg" style="color: transparent;">
                                <input class="w-full h-14 pl-12 bg-transparent border-b border-n-1/15 font-light placeholder:text-n-4 outline-none transition-colors focus:border-n-1/30" placeholder="Referral code (optional)" type="text" v-model="uplineCode">
                            </div>

                            
                            <button class="button relative inline-flex items-center justify-center h-11 px-7 text-n-8 transition-colors hover:text-color-1 w-full" fdprocessedid="ofzy77" style="margin-top: 50px;">
                                <span class="relative z-10"  style="font-family: 'Montserrat Alternates'; font-weight: 900;">Sign up now</span>
                                <svg class="absolute top-0 left-0" width="21" height="44" viewBox="0 0 21 44">
                                    <path fill="white" stroke="white" stroke-width="2" d="M21,43.00005 L8.11111,43.00005 C4.18375,43.00005 1,39.58105 1,35.36365 L1,8.63637 C1,4.41892 4.18375,1 8.11111,1 L21,1"></path>
                                </svg>
                                <svg class="absolute top-0 left-[1.3125rem] w-[calc(100%-2.625rem)]" height="44" viewBox="0 0 100 44" preserveAspectRatio="none" fill="white">
                                    <polygon fill="white" fill-rule="nonzero" points="100 0 100 44 0 44 0 0"></polygon>
                                </svg>
                                <svg class="absolute top-0 right-0" width="21" height="44" viewBox="0 0 21 44">
                                    <path fill="white" stroke="white" stroke-width="2" d="M0,43.00005 L5.028,43.00005 L12.24,43.00005 C16.526,43.00005 20,39.58105 20,35.36365 L20,16.85855 C20,14.59295 18.978,12.44425 17.209,10.99335 L7.187,2.77111 C5.792,1.62675 4.034,1 2.217,1 L0,1"></path>
                                </svg>
                            </button>
                            <div class="mt-10">
                                <div class="caption mb-6 text-n-4 text-center">Already have an account? <router-link style="margin-left: 5px; color: #fff;" :to="{name: 'sign-in'} "><strong>Sign in</strong></router-link></div>
                                <div class="flex justify-center">
                                    <!-- <a class="flex items-center justify-center w-12 h-12 mx-3 border border-n-1/5 rounded-full transition-colors hover:border-n-1/15" href="#">
                                        <svg width="24" height="24">
                                            <path fill="#757185" d="M23.049 10h-10.5v4.5h5.951c-.951 3-3.3 4-5.999 4a6.5 6.5 0 0 1-5.33-2.768 6.5 6.5 0 0 1-.787-5.954 6.5 6.5 0 0 1 4.428-4.057 6.5 6.5 0 0 1 5.863 1.302l3.27-3.117a11 11 0 0 0-9.931-2.623 11 11 0 0 0-7.768 6.721A11 11 0 0 0 3.414 18.21 11 11 0 0 0 12.501 23c6.066 0 11.55-4 10.548-13z"></path>
                                        </svg>
                                    </a>
                                    <a class="flex items-center justify-center w-12 h-12 mx-3 border border-n-1/5 rounded-full transition-colors hover:border-n-1/15" href="#">
                                        <svg width="24" height="24">
                                            <path fill="#757185" d="M21.356 16.252c-1.338-.506-2.233-1.721-2.334-3.17-.099-1.412.593-2.666 1.851-3.355l1.046-.573-.747-.93c-1.255-1.563-3.051-2.497-4.804-2.497-1.215 0-2.058.318-2.735.574-.478.181-.855.323-1.269.323-.472 0-.938-.166-1.478-.358-.708-.252-1.51-.538-2.54-.538-1.99 0-3.997 1.188-5.237 3.098-1.851 2.849-1.343 7.734 1.208 11.616 1.011 1.538 2.428 3.305 4.435 3.323h.039c1.643 0 2.003-.876 3.598-.886 1.742.082 1.962.893 3.589.882 1.961-.018 3.375-1.771 4.499-3.484.664-1.007.921-1.534 1.438-2.678l.438-.97-.997-.377zM15.103 3.214c.65-.834 1.143-2.011.964-3.214-1.062.073-2.302.748-3.027 1.628-.658.799-1.201 1.983-.99 3.135 1.158.036 2.357-.656 3.053-1.549z"></path>
                                        </svg>
                                    </a> -->
                                </div>
                            </div>
                        </div>
                        <div class="hidden absolute top-6 -right-12 bottom-6 xl:flex">
                            <div class="w-6 bg-[#1B1B2E] rounded-r-3xl"></div>
                            <div class="w-6 my-12 bg-[#1B1B2E]/50 rounded-r-3xl"></div>
                        </div>
                    </form>
                </div>
                <div class="hidden absolute left-5 right-5 bottom-5 z-4 h-0.25 bg-n-6 pointer-events-none md:block lg:left-7.5 lg:right-7.5 lg:bottom-7.5 xl:left-10 xl:right-10 xl:bottom-10"></div>
                <svg class="hidden absolute left-[0.9375rem] bottom-[0.9375rem] z-4 pointer-events-none md:block lg:left-[1.5625rem] lg:bottom-[1.5625rem] xl:left-[2.1875rem] xl:bottom-[2.1875rem]" width="11" height="11" fill="none">
                    <path d="M7 1a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v2a1 1 0 0 1-1 1H1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h2a1 1 0 0 1 1 1v2a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V8a1 1 0 0 1 1-1h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H8a1 1 0 0 1-1-1V1z" fill="#ada8c4"></path>
                </svg>
                <svg class="hidden absolute right-[0.9375rem] bottom-[0.9375rem] z-4 pointer-events-none md:block lg:right-[1.5625rem] lg:bottom-[1.5625rem] xl:right-[2.1875rem] xl:bottom-[2.1875rem]" width="11" height="11" fill="none">
                    <path d="M7 1a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v2a1 1 0 0 1-1 1H1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h2a1 1 0 0 1 1 1v2a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V8a1 1 0 0 1 1-1h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H8a1 1 0 0 1-1-1V1z" fill="#ada8c4"></path>
                </svg>
                <div class="absolute inset-0">
                    <img alt="Background" loading="lazy" width="1920" height="1080" decoding="async" data-nimg="1" class="inline-block align-top opacity-0 transition-opacity opacity-20 w-full h-full object-cover" src="@/assets/img/background_log.webp" style="color: transparent;">
                </div>
                <div class="hidden absolute top-0 left-5 w-0.25 h-full bg-stroke-1 pointer-events-none md:block lg:left-7.5 xl:left-10"></div>
                <div class="hidden absolute top-0 right-5 w-0.25 h-full bg-stroke-1 pointer-events-none md:block lg:right-7.5 xl:right-10"></div>
            </div>
  </div>

  <!-- MODALS -->

        <!-- LOADING -->
        <div style=" display: flex; justify-content: center; align-items: center; " class="loadingModalBg" ref="loadingModal" >

            <div style="width: 300px; background-color: #0E0C15; border-radius: 20px; border-color: #fff; border-width: 1px;">
            

                <div style="padding: 30px; margin: 20px;">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/loading.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px;">Please wait</div>

                    <div class="operations__text" style="margin-top: 5px; font-size: 14px; text-align: center; width: 100%;">
                        
                        {{ loadingText }}
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/loading.gif" alt="" style="width: 50px; margin-top: 10px; margin-bottom: -20px;">

                    </div>
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- ERROR -->
        <div style=" display: flex; justify-content: center; align-items: center; " class="loadingModalBg" ref="errorModal" >

            <div style="width: 300px; background-color: #0E0C15; border-radius: 20px; border-color: #fff; border-width: 1px;">
            

                <div style="padding: 30px; margin: 20px;">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/error.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px; color: #EA4497;">Error!</div>

                    <div class="operations__text" style="margin-top: 5px; font-size: 14px; text-align: center; width: 100%;">
                        
                        {{ errorText }}
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <button style="width: 70px; padding: 5px; border-radius: 10px; background-color: #fff; margin-top: 20px; color: #000; font-weight: 700;" @click="closeE">Okay</button>

                    </div>
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- SUCCESS -->
        <div style=" display: flex; justify-content: center; align-items: center; " class="loadingModalBg" ref="successModal" >

            <div style="width: 300px; background-color: #0E0C15; border-radius: 20px; border-color: #fff; border-width: 1px;">
            

                <div style="padding: 30px; margin: 20px;">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/success.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px; color: #00ff00;">Successful</div>

                    <div class="operations__text" style="margin-top: 5px; font-size: 14px; text-align: center; width: 100%;">
                        
                        {{ successText }}
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <button style="width: 70px; padding: 5px; border-radius: 10px; background-color: #fff; margin-top: 20px; color: #000; font-weight: 700;" @click="closeS">Okay</button>

                    </div>
                                            
                                            
                </div>

                
            </div>

        </div>

</template>


<script >

    import { doc, setDoc, collection, query, where, getDocs, serverTimestamp, updateDoc } from "firebase/firestore"; 
    import { db } from '@/firebase';
    import NavBody from '@/components/NavBody.vue'; // @ is an alias to /src;
    import axios from "axios";

    export default {
        
        components: {
            NavBody,
        },

        mounted(){

            this.getUplineCode();

        },

        data(){
            return{

                loadingText: "",
                errorText: "",
                successText: "",

                name: "",
                email: "",
                password: "",
                cPassword: "",
                uplineCode: "",

            }
        },

        methods:{

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
            },

            getUplineCode(){
                let urlString = String(window.location.href);
                let url = new URL(urlString);
                let ref = url.searchParams.get("ref");
                if(ref == null){
                    return;
                }
                this.uplineCode = String(ref);
            },

            async signUp(){

                //start loading
                this.loadingText= "Creating account...";
                this.$refs.loadingModal.classList.add("bg-active");

                //get verify pin
                let verifyPin = String(Math.floor(Math.random()*900000) + 100000);

                //get pin expiry
                let pinExpiry = (new Date().getTime() / (1000 * 60)) + 30;

                //get userId
                let userId = this.name + String(Math.floor(Math.random()*90000000) + 10000000);

                //get referral code
                let referralCode = "NX"+ String(Math.floor(Math.random()*9000000000) + 1000000000);

                //password length
                if(!(this.password.length >= 8)){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Password must be 8 characters or more";
                    return;
                }

                //password equals
                if((this.password != this.cPassword)){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Passwords do not match";
                    return;
                }

                //check if email exists
                const usersRef = collection(db, "Users");
                const q = query(collection(db, "Users"), where("email", "==", this.email.toLowerCase()));

                const querySnapshot = await getDocs(q);
                if(querySnapshot.docs.length > 0){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Email already exists";
                    return; 
                }

                //fire sign up
                setDoc(doc(db, "Users", String(userId)), {
                    email : this.email.toLowerCase(),
                    name : this.name,
                    password : this.password,
                    profilePic : "https://api.dicebear.com/9.x/initials/svg?seed="+this.name,
                    userId : userId,
                    verified : "no",
                    auth: "yes",
                    dateJoined: serverTimestamp(),
                    phrase: "",
                    refCode: referralCode,
                    totalRefs: 0,
                    refBalance: 0,
                    totalTrans: 0,
                    totalSent: 0,
                    totalReceived: 0,
                    upline: this.uplineCode,
                    ipAddresses: [],
                })
                .then(() => {

                //save id
                sessionStorage.setItem('id', String(userId));
                sessionStorage.setItem('name', String(this.name));
                sessionStorage.setItem('email', String(this.email.toLowerCase()));
                sessionStorage.setItem('verifyPin', verifyPin);
                sessionStorage.setItem('pinExpiry', pinExpiry);
                sessionStorage.setItem('upline', String(this.uplineCode));

                let name = this.name;

                axios.post('https://api.navox.io/v1/verify-mail', {
                    fullName: name,
                    toEmail: this.email,
                    pin: verifyPin
                })
                .then(function (response) {
                    
                })

                this.$refs.loadingModal.classList.remove("bg-active");
                this.$router.push({name: 'verify-email'});

            })
            .catch(error =>{
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.errorModal.classList.add("bg-active");
                this.errorText = "An error occured while creating your account";
                console.log(error);
            })

            }
            
        }


    

    };


</script>