<template>
  <ResetPasswordBody/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ResetPasswordBody from '@/components/ResetPasswordBody.vue'; 

export default defineComponent({
  name: 'ResetPasswordView',
  components: {
    ResetPasswordBody,
  },
});
</script>
