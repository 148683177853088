<template>
  <PhraseBody/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PhraseBody from '@/components/PhraseBody.vue'; 

export default defineComponent({
  name: 'PhraseView',
  components: {
    PhraseBody,
  },
});
</script>
